import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import ircuno from "../../Assets/Projects/ircuno.png";
import tripeaks from "../../Assets/Projects/tripeaks.jpeg";
import mp3amp from "../../Assets/Projects/mp3amp.png";
import typedna from "../../Assets/Projects/typedna.png";
import solclass from "../../Assets/Projects/solclass.jpg";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Some of My <strong className="purple">Works</strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on in the past. All of these except for TypeDNA were solo projects.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>

        <Col md={4} className="project-card">
            <ProjectCard
              imgPath={ircuno}
              isBlog={false}
              title="IRC UNO (2010-2020)"
              description="A full UNO game plugin that was initially created for the Phenny IRC bot, and was later migrated to the Sopel bot as the former was abandoned. It features (almost) the full official UNO rules, high score tables and other neat features. It was developed in Python and a few external contributors have kept it alive over the years."
              ghLink="https://github.com/tmarki/sopel-UnoBot"
            />
          </Col>

        <Col md={4} className="project-card">
            <ProjectCard
              imgPath={tripeaks}
              isBlog={false}
              title="Classic Tri Peaks Solitaire (2015)"
              description="Classic Tri Peaks Solitaire is a fun card game that I developed from scratch in the Unity3D environment. It was my first project with C# and I had a lot of fun learning the language and the new environment. It met great success, some players are still playing it every day despite the lack of updates. I worked on several other Unity3D projects afterwards, but I pulled most of them from the Play Store since I don't have time to keep them updated."
              psLink="https://play.google.com/store/apps/details?id=com.tmarki.tripeaks"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={typedna}
              isBlog={false}
              title="TypeDNA (2009)"
              description="TypeDNA (also the name of the product) is cross-platform, standalone font management software that also includes some advanced features to help you select fonts based on similarity and type features. Along with standard activation and grouping features, TypeDNA includes Font Harmony and Similar Fonts tools to help you select aesthetically-compatible fonts for your projects. Sadly it was discontinued in 2014."
              articleLink="https://www.engadget.com/2009-01-07-typedna-to-pick-up-where-other-font-management-software-leaves-o.html"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={solclass}
              isBlog={false}
              title="Solitaire Classic (2012)"
              description="Solitaire Classic is a full-featured solitaire game for Android. It was developed in IntelliJ Idea with the LibGDX graphic library - it was all done from scratch. It also used to employ Google Play Games and other extra integrations. It reached over 1 million downloads and amassed a significant user base."
              psLink="https://play.google.com/store/apps/details?id=com.tmarki.solitaire"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={mp3amp}
              isBlog={false}
              title="MP3 Amplifier (2011)"
              description="MP3 Amplifier is an application for older and weaker Android phones that can create an amplified copy of MP3 files, increasing the sound level for ringtones and other sound files. It was developed in Android Studio with the Android Native Development Kit, using some open source components."
              psLink="https://play.google.com/store/apps/details?id=com.tmarki.mp3amplifier"
            />
          </Col>

  </Row>
      </Container>
    </Container>
  );
}

export default Projects;
