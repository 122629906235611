import React from "react";
import Card from "react-bootstrap/Card";
import { ImPointRight } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            I am <span className="purple">Tamás Márki</span>, 
            I live in <span className="purple"> Saskatoon, Saskatchewan, Canada.</span>
            <br /> I moved here from Serbia in 2018 with my wife, <span className="purple">Tina</span> and our son, <span className="purple">Mark</span>.
            <br />
            I am currently looking for new adventures, so if you think I could make a contribution to your team then don't be afraid to reach out.
            <br />
            <br />
            Apart from coding I have the following hobbies:
          </p>
          <ul>
            <li className="about-activity">
              <ImPointRight /> Hiking and riding my bike
            </li>
            <li className="about-activity">
              <ImPointRight /> Playing table tennis
            </li>
            <li className="about-activity">
              <ImPointRight /> Video gaming
            </li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
