import React from "react";
import { Tooltip } from 'react-tooltip'
import { Col, Row } from "react-bootstrap";
import {
  SiGnometerminal,
  SiIntellijidea,
  SiLinux,
  SiSlack,
  SiVisualstudiocode
} from "react-icons/si";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons" data-tooltip-id="my-tooltip" data-tooltip-content="Linux">
        <SiLinux />
      </Col>
      <Col xs={4} md={2} className="tech-icons" data-tooltip-id="my-tooltip" data-tooltip-content="Visual Studio Code">
        <SiVisualstudiocode />
      </Col>
      <Col xs={4} md={2} className="tech-icons" data-tooltip-id="my-tooltip" data-tooltip-content="Terminal">
        <SiGnometerminal />
      </Col>
      <Col xs={4} md={2} className="tech-icons" data-tooltip-id="my-tooltip" data-tooltip-content="Slack">
        <SiSlack />
      </Col>
      <Col xs={4} md={2} className="tech-icons" data-tooltip-id="my-tooltip" data-tooltip-content="IntelliJ Idea">
        <SiIntellijidea />
      </Col>
      <Tooltip id="my-tooltip" style={{ width: "200px" }} />
    </Row>
  );
}

export default Toolstack;
