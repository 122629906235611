import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Tilt from "react-parallax-tilt";
import {
  AiFillMail, AiFillPhone,

} from "react-icons/ai";
import { FaGithub, FaLinkedinIn } from "react-icons/fa";
import Particle from "../Particle";

function Home() {
  return (
    <section>
    <Container fluid className="about-section" id="about">
      <Particle />
      <Container>
        <Row>
          <Col md={8} className="home-about-description">
            <h1 style={{ fontSize: "2.6em" }}>
              Tamás Márki
            </h1>
            <p className="home-about-body">
              I have been programming since I was 12.
              <br />
              <br />My preferred languages are
                <b className="purple"> Java, Typescript,</b> and <b className="purple">Python </b>, but I am flexible and enjoy learning new things.
              <br />
              <br />
              My main interests are
                <b className="purple"> scalable cloud-native APIs</b> and
                {" "}
                <b className="purple">
                  serverless solutions.
                </b>
              <br />
              <br />
              During my professional career I worked 
              in a lot of spaces from <b className="purple">Windows desktop applications</b> through
                <b className="purple">
                  {" "}
                  high capacity backend services 
                  {" "}
                </b>
               to
                <b className="purple"> mobile apps and games.</b>
            </p>
          </Col>
          <Col md={4} className="myAvtar">
            <Tilt>
              <img src="me.png" className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <p>
              Feel free to <span className="purple">connect </span>with me
            </p>
            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://github.com/tmarki"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaGithub />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://www.linkedin.com/in/tamasmarki/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <FaLinkedinIn />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="mailto:tmarki@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="tel:+1-306-880-0207"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillPhone />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
    </section>
  );
}

export default Home;
