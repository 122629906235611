import React from "react";
import { Col, Row } from "react-bootstrap";
import { Tooltip } from 'react-tooltip';
import {
  DiJavascript1,
  DiReact,
  DiNodejs,
  DiMongodb,
  DiPython,
  DiGit,
  DiJava,
} from "react-icons/di";
import {
  SiRedis,
  SiFirebase,
  SiNextdotjs,
  SiSolidity,
  SiPostgresql,
  SiTypescript,
  SiJavascript,
  SiPython,
  SiNodedotjs,
  SiAmazonsqs,
  SiServerless,
  SiJest,
  SiAmazondynamodb,
  SiAwslambda,
  SiAmazonaws,
  SiAmazonrds,
  SiJunit5,
  SiReact,
  SiHtml5,
  SiAmazonecs,
  SiAmazoncloudwatch

} from "react-icons/si";
import { TbBrandGolang } from "react-icons/tb";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons" data-tooltip-id="my-tooltip" data-tooltip-content="Typescript">
        <SiTypescript />
      </Col>
      <Col xs={4} md={2} className="tech-icons" data-tooltip-id="my-tooltip" data-tooltip-content="Python">
        <SiPython />
      </Col>
      <Col xs={4} md={2} className="tech-icons" data-tooltip-id="my-tooltip" data-tooltip-content="Java">
        <DiJava />
      </Col>
      <Col xs={4} md={2} className="tech-icons" data-tooltip-id="my-tooltip" data-tooltip-content="Node.js">
        <SiNodedotjs />
      </Col>
      <Col xs={4} md={2} className="tech-icons" data-tooltip-id="my-tooltip" data-tooltip-content="MongoDB">
        <DiMongodb />
      </Col>
      <Col xs={4} md={2} className="tech-icons" data-tooltip-id="my-tooltip" data-tooltip-content="Serverless">
        <SiServerless />
      </Col>
      <Col xs={4} md={2} className="tech-icons" data-tooltip-id="my-tooltip" data-tooltip-content="Git">
        <DiGit />
      </Col>
      <Col xs={4} md={2} className="tech-icons" data-tooltip-id="my-tooltip" data-tooltip-content="Redis">
        <SiRedis />
      </Col>
      <Col xs={4} md={2} className="tech-icons" data-tooltip-id="my-tooltip" data-tooltip-content="Postgresql">
        <SiPostgresql />
      </Col>
      <Col xs={4} md={2} className="tech-icons" data-tooltip-id="my-tooltip" data-tooltip-content="Jest">
        <SiJest />
      </Col>
      <Col xs={4} md={2} className="tech-icons" data-tooltip-id="my-tooltip" data-tooltip-content="Amazon SQS">
        <SiAmazonsqs />
      </Col>
      <Col xs={4} md={2} className="tech-icons" data-tooltip-id="my-tooltip" data-tooltip-content="Amazon DynamoDB">
        <SiAmazondynamodb />
      </Col>
      <Col xs={4} md={2} className="tech-icons" data-tooltip-id="my-tooltip" data-tooltip-content="AWS Lambda">
        <SiAwslambda />
      </Col>
      <Col xs={4} md={2} className="tech-icons" data-tooltip-id="my-tooltip" data-tooltip-content="Amazon Web Services">
        <SiAmazonaws />
      </Col>
      <Col xs={4} md={2} className="tech-icons" data-tooltip-id="my-tooltip" data-tooltip-content="Amazon RDS">
        <SiAmazonrds />
      </Col>
      <Col xs={4} md={2} className="tech-icons" data-tooltip-id="my-tooltip" data-tooltip-content="Amazon ECS">
        <SiAmazonecs />
      </Col>
      <Col xs={4} md={2} className="tech-icons" data-tooltip-id="my-tooltip" data-tooltip-content="Amazon CloudWatch">
        <SiAmazoncloudwatch />
      </Col>
      <Col xs={4} md={2} className="tech-icons" data-tooltip-id="my-tooltip" data-tooltip-content="JUnit">
        <SiJunit5 />
      </Col>
      <Col xs={4} md={2} className="tech-icons" data-tooltip-id="my-tooltip" data-tooltip-content="React">
        <SiReact />
      </Col>
      <Col xs={4} md={2} className="tech-icons" data-tooltip-id="my-tooltip" data-tooltip-content="HTML5">
        <SiHtml5 />
      </Col>
      <Tooltip id="my-tooltip" style={{ width: "200px" }} />
    </Row>
  );
}

export default Techstack;
